import '../styles/accordion.scss';

import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import iconback from '../images/icon-back-black.svg';
import righttick from '../images/images/icon-tick-green.svg';
import info from '../images/images/info-24-px-2.png';
import UpArrow from '../images/UpArrow.jpeg';
//import ReactTooltip from 'react-tooltip';
import * as headerStyles from '../styles/common.module.css';
//import PersonWithPapers from '../images/group-3@2x.png';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  for ( let i = 0; i < data.length; i++ ) {
    if ( data[i].node.attribute_3_key === 'order' &&
      !uniqueSectionArray.includes( data[i].node.content ) ) {
      uniqueSectionArray.push( data[i].node.content );
    } else if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let search = [];
  search = data.filter( e => e.node.attribute_3_value );
  search = search.map( e => e.node );

  let section;
  let column;
  for ( let i = 0; i < sectionsArray.length; i++ ) {
    output[sectionsArray[i]] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionsArray[i] && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionsArray[i]]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      for ( let j = 0; j < column.length; j++ ) {
        output[sectionsArray[i]]['column_' + col][column[j].node.style_category] = column[j].node;
      }
    }
  }
  // console.log(output.section_4, "output");
  return output;
};

let productHightlightsArray = ( fmtData2 ) => {
  let output = [];

  for ( let i = 0; i < fmtData2.section_2.column_1.product_highlights_text.length; i++ ) {
    output.push( fmtData2.section_2.column_1.product_highlights_text[i] );
  }

};

const CarrierPlanPage = () => {

  const data = useStaticQuery(
    graphql`
  query {
      allStrapiPageData(filter: {page_name: {eq: "pacific_life_next_life_plan_page"}}) {
        edges {
          node {
            id
            page_name
            style_category
            content
            attribute_1_key
            attribute_1_value
            attribute_2_key
            attribute_2_value
            attribute_3_value
            column
            data_category
            version
          }
      }
    }
    faqs : allStrapiPageData(filter: {page_name: {in: ["paclife_faq_page"]}} sort: {fields: attribute_3_value, order: ASC}) {
      edges {
        node {
          id
          page_name
          style_category
          content
          attribute_1_key
          attribute_1_value
          attribute_2_key
          attribute_2_value
          column
          data_category
          version
        }
      }
    }
    faq_sections : allStrapiPageData(filter: {page_name: {eq: "paclife_faq_page_section"}} sort: {fields: attribute_3_value, order: ASC}) {
      edges {
      node {
        id
        page_name
        style_category
        content
        attribute_1_key
        attribute_1_value
        attribute_2_key
        attribute_2_value
        attribute_3_key
        attribute_3_value
        column
        data_category
        version
      }
    }
  }
}
`
  );

  let pageData = data.allStrapiPageData.edges;
  //  console.log( 'Plan Page: ', pageData );
  let uniqueSectionData = getUniqueSectionData( pageData );
  // let fmtData = getformattedData(pageData, uniqueSectionData);
  let fmtData2 = getformattedData2( pageData, uniqueSectionData );
  // console.log( 'fmtData: ', fmtData2 );
  let uniqueFAQSections = getUniqueSectionData( data.faq_sections.edges );

  // console.log( 'uniqueFAQSectionsuniqueFAQSectionsuniqueFAQSectionsuniqueFAQSectionsuniqueFAQSections: ', uniqueFAQSections );

  let visited = [];

  for ( let i = 0; i < uniqueFAQSections.length; i++ ) {
    visited[uniqueFAQSections[i]] = false;

  }


  const [ showResults, setShowResults ] = React.useState( {} );
  const [ showSections, setShowSections ] = React.useState( {} );
  const [ showIcon, setShowIcon ] = React.useState( true );
  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    console.log( 'index' );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };


  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> ' + event.target.name );
    console.log( 'section' );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick_dd_icons = () => {
    setShowIcon( !showIcon );
  };


  let maxY;

  const [ active, setActive ] = useState( 0 );
  // const { innerWidth } = window;

  const handleClickOutside = e => {
    if ( active ) {
      if ( maxY && maxY < e.pageY ) {
        setActive( 0 );
      }
    }
  };
  useEffect( () => {
    document.addEventListener( 'mousedown', handleClickOutside );
    return () => document.removeEventListener( 'mousedown', handleClickOutside );
  } );

  productHightlightsArray( fmtData2 );
  return (
    <Layout header={'menu'}>
      <SEO
        title="Next by Pacific Life Term Life Insurance Policy Quotes"
        description="Next Term Life an innovative term life insurance policy Backed by Pacific Life. Find out which plan is perfect for you and get your quote now at Bubble Insurance."
        keywords="Term Life Insurance Policy, Term Insurance, Next by Pacific Life, Term Life Insurance quotes online, Online term life insurance"
      />

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img className="pb-3" src={fmtData2.section_1.column_1.banner_title.attribute_1_value} alt="bgimg"/>

                <h1
                  className="carrier-plan-main-heading"
                  style={{ fontSize: '35px' }}
                >
                  {fmtData2.section_1.column_1.banner_title.content}
                </h1>

                <p className="carrier-plan-section1-para">
                  {fmtData2.section_1.column_1.banner_text.content}

                  <sup className="orange-sup">1</sup>
                </p>

                <div className="flexRow btns mobflexbtn">
                  <a href="/" className="orangeBtn text-center">{fmtData2.section_1.column_1.orange_button_link.content}</a>

                  <button type="button" className="explore">
                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      {fmtData2.section_1.column_1.white_button_link.content}
                    </a>
                  </button>
                </div>
              </div>

              <div className="col-lg-6 specific-life" style={{ textAlign: 'center' }}>
                <img src={fmtData2.section_1.column_2.banner_image.attribute_1_value} className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0" alt="bgimg"/>
              </div>
            </div>

            {/*{fmtData2.section_1.column_2.banner_image.attribute_1_value} <div class="col-lg-2"></div> */}
          </div>


        </div>


        <div className="container-fluid carrier-plan-dropdown" id="start-exploring">
          <div className="row carrier-plan-dropdownHidden">
            <div className="col-lg-2"></div>

            <div className="col-lg-6 align-dd">
              <div className="panel-group align-panels pr-5">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <div className="dd-heading-align">
                      <div className="panel-title dd-title">
                        Life Insurance 101
                      </div>

                      <a data-toggle="collapse" href="#collapse1"><img src={showIcon ? iconback : ''} onClick={function () { onClick_dd_icons(); }} alt="arrow"/></a>
                    </div>
                  </div>

                  <div id="collapse1" className="panel-collapse collapse pb-3">
                    <ul className="list-group style-dd-list">
                      <a href="#faqs"><li>What is+ Life Insurance?</li></a>

                      <a href="#faqs"><li>How Does it work?</li></a>
                    </ul>

                  </div>
                </div>
              </div>

              <div className="panel-group align-panels pr-5">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <div className="dd-heading-align">
                      <div className="panel-title dd-title">
                        Coverage and Costs
                      </div>

                      <a data-toggle="collapse" href="#collapse1"><img src={showIcon ? iconback : ''} onClick={function () { onClick_dd_icons(); }} alt="arrow"/></a>
                    </div>
                  </div>

                  <div id="collapse1" className="panel-collapse collapse pb-3">
                    <ul className="list-group style-dd-list">
                      <a href="#faqs"><li>How much does Life Insurance cost?</li></a>

                      <a href="#faqs"><li>How much Life Insurance do I need?</li></a>

                      <a href="#faqs"><li>How long should my coverage last?</li></a>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="panel-group align-panels">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <div className="dd-heading-align">
                      <div className="panel-title dd-title">
                        Our Offerings
                      </div>

                      <a data-toggle="collapse" href="#collapse1"><img src={showIcon ? iconback : ''} onClick={function () { onClick_dd_icons(); }} alt="arrow"/></a>
                    </div>
                  </div>

                  <div id="collapse1" className="panel-collapse collapse pb-3">
                    <ul className="list-group style-dd-list">
                      <a href="#faqs"><li>Pacific Life Next</li></a>

                      <a href="#faqs"><li>SBLI Term</li></a>

                      <a href="#faqs"><li>Symetra Swift Term</li></a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4"></div>
          </div>
        </div>

        <div className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3
                className="carrier-plan-heading"
                style={{ textAlign: 'center' }}
              >
                {fmtData2.section_2.column_1.title.content}
              </h3>

              <p className="carrier-plan-section2-para">
                {fmtData2.section_2.column_1.text_1.content}
              </p>

              <div className="carrier-plan-section2-para">
                <div dangerouslySetInnerHTML={{ __html: fmtData2.section_2.column_1.text_2_sub_1.content }}></div>
              </div>
            </div>

            <div className="col-lg-3"></div>
          </div>

          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 product-container-alignment costom-padding16 NextTermBox">
              <div className="carrier-product-container">

                <img className="illustration-alignment ph-desktop-view ProductImage"
                  src={fmtData2.section_2.column_1.product_highlights_title.attribute_1_value}
                  alt={fmtData2.section_2.column_1.product_highlights_title.attribute_2_value}>

                </img>

                <h3
                  style={{ marginTop: '10px', textAlign: 'center' }}
                  className="carrier-plan-heading ph-desktop-view">
                  {fmtData2.section_2.column_1.product_highlights_title.content}
                </h3>

                <h3
                  style={{ marginTop: '10px', textAlign: 'center' }}
                  className="carrier-plan-heading ph-mobile-view">
                  {fmtData2.section_2.column_1.product_highlights_title.content}
                </h3>

                <img className="illustration-alignment ph-mobile-view"
                  src={fmtData2.section_2.column_1.product_highlights_title.attribute_1_value}
                  alt={fmtData2.section_2.column_1.product_highlights_title.attribute_2_value}>
                </img>

                {data.allStrapiPageData.edges.map( ( d, i ) => {
                  if ( d.node.style_category == 'product_highlights_text' ) {

                    return (
                      <div key={i} style={{ display: 'flex', alignItems: 'center' }} className="align-ph-list mb-2">
                        <img src={righttick} style={{ marginRight: '10px' }} alt="tick"/>

                        <div className="save-para" style={{ lineHeight: 'normal' }}>
                          {d.node.content}
                        </div>
                      </div>
                    );
                  }
                } )}
              </div>

            </div>

            <div className="col-lg-3"></div>
          </div>

        </div>

        <div className="card-bg-containers section70">
          <div className="container " style={{ paddingBottom: '30px' }}>
            <h3 className="carrier-plan-heading" style={{ textAlign: 'center' }}>
              {fmtData2.section_3.column_1.title.content}</h3>

            <div className="row">
              <div className="col-lg-1">
              </div>

              <div className="col-lg-5 pr-lg-2 pr-0 costom-padding16">
                <div className="card card-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                  <div className="card-body">
                    <img className="illustration-alignment" src={fmtData2.section_3.column_1.card_1_title.attribute_1_value}
                      alt={fmtData2.section_3.column_1.card_1_title.attribute_2_value} ></img>

                    <h4 className="inner-container-heading" style={{ textAlign: 'center' }}>
                      {fmtData2.section_3.column_1.card_1_title.content}
                    </h4>

                    <p className="carrier-container-para">
                      {fmtData2.section_3.column_1.card_1_text.content}
                    </p>

                    <div className="BenifitsOfNextermBox" style={{ textAlign: 'center' }}>
                      <div className="carrier-card">
                        <img src={fmtData2.section_3.column_1.card_1_icon_1.attribute_1_value}
                          alt={fmtData2.section_3.column_1.card_1_icon_1.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_1.card_1_icon_1.content}
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_1.card_1_icon_1.attribute_3_value}</div>
                        </div>
                      </div>

                      <div className="carrier-card carrier-card-mobview">
                        <img src={fmtData2.section_3.column_1.card_1_icon_2.attribute_1_value}
                          alt={fmtData2.section_3.column_1.card_1_icon_2.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_1.card_1_icon_2.content}
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_1.card_1_icon_2.attribute_3_value}</div>
                        </div>
                      </div>

                      <div className="carrier-card">
                        <img src={fmtData2.section_3.column_1.card_1_icon_3.attribute_1_value}
                          alt={fmtData2.section_3.column_1.card_1_icon_3.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_1.card_1_icon_3.content}
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_1.card_1_icon_3.attribute_3_value}</div>
                        </div>
                      </div>

                      <div className="carrier-card carrier-card-mobview">
                        <img src={fmtData2.section_3.column_1.card_1_icon_4.attribute_1_value}
                          alt={fmtData2.section_3.column_1.card_1_icon_4.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_1.card_1_icon_4.content}
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_1.card_1_icon_4.attribute_3_value}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 pl-lg-2 pr-0 costom-padding16">
                <div className="card card-container mb-3  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                  <div className="card-body">
                    <img className="illustration-alignment" src={fmtData2.section_3.column_2.card_1_title.attribute_1_value}
                      alt={fmtData2.section_3.column_2.card_1_title.attribute_2_value}></img>

                    <h4 className="inner-container-heading" style={{ textAlign: 'center' }}>
                      {fmtData2.section_3.column_2.card_1_title.content}
                    </h4>

                    <p className="carrier-container-para">
                      {fmtData2.section_3.column_2.card_1_text.content}<sup className="orange-sup">1</sup>
                    </p>

                    <div style={{ textAlign: 'center' }}>
                      <div className="save-divs">
                        <div className="align-save-divs"><img src={fmtData2.section_3.column_2.card_1_icon_1_heading.attribute_1_value}
                          alt={fmtData2.section_3.column_2.card_1_icon_1_heading.attribute_2_value} className="mt-3"></img></div>

                        <div> <p
                          className="carrier-plan-heading"
                          style={{ marginBottom: '2px' }}
                        >
                          {fmtData2.section_3.column_2.card_1_icon_1_heading.content}
                        </p>

                        <p className="save-para">
                          {fmtData2.section_3.column_2.card_1_icon_1_text.content}

                          <sup className="orange-sup">1</sup>
                        </p>
                        </div>

                      </div>

                      <div className="save-divs">
                        <div className="align-save-divs"><img src={fmtData2.section_3.column_2.card_1_icon_2_heading.attribute_1_value}
                          alt={fmtData2.section_3.column_2.card_1_icon_2_heading.attribute_2_value} className="mt-3"></img></div>

                        <div><p
                          className="carrier-plan-heading"
                          style={{ marginBottom: '2px' }}
                        >
                          {fmtData2.section_3.column_2.card_1_icon_2_heading.content}
                        </p>

                        <p className="save-para">
                          {fmtData2.section_3.column_2.card_1_icon_2_text.content}
                        </p>
                        </div>

                      </div>
                    </div>

                    <div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-1">
              </div>
            </div>

            <div className="row">
              <div className="col-lg-1">
              </div>

              <div className="col-lg-5 pr-lg-2 pr-0 costom-padding16">
                <div className="card card-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                  <div className="card-body">
                    <img className="illustration-alignment" src={fmtData2.section_3.column_1.card_2_title.attribute_1_value}
                      alt={fmtData2.section_3.column_1.card_2_title.attribute_2_value}></img>

                    <h4 className="inner-container-heading" style={{ textAlign: 'center' }}>
                      {fmtData2.section_3.column_1.card_2_title.content}
                    </h4>

                    <p className="carrier-container-para">
                      {fmtData2.section_3.column_1.card_2_text.content}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 pl-lg-2 pr-0 costom-padding16">
                <div className="card card-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                  <div className="card-body">
                    <img className="illustration-alignment" src={fmtData2.section_3.column_2.card_2_title.attribute_1_value}
                      alt={fmtData2.section_3.column_2.card_2_title.attribute_2_value}></img>

                    <h4 className="inner-container-heading" style={{ textAlign: 'center' }}>
                      {fmtData2.section_3.column_2.card_2_title.content}
                    </h4>

                    <p className="carrier-container-para">
                      {fmtData2.section_3.column_2.card_2_text.content}
                    </p>

                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                      <div className="carrier_card">
                        <img src={fmtData2.section_3.column_2.card_2_icon_1_text.attribute_1_value}
                          alt={fmtData2.section_3.column_2.card_2_icon_1_text.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_2.card_2_icon_1_text.content}
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_2.card_2_icon_1_text.attribute_3_value}</div>
                        </div>
                      </div>

                      <div className="carrier_card padding-left-40">
                        <img src={fmtData2.section_3.column_2.card_2_icon_2_text.attribute_1_value}
                          alt={fmtData2.section_3.column_2.card_2_icon_2_text.attribute_2_value} className="align-icon-cap"></img>

                        <h5 className="carrier-icon-cap mb-0">
                          {fmtData2.section_3.column_2.card_2_icon_2_text.content}<sup className="orange-sup">2</sup>
                        </h5>

                        <div className="tooltipbox">
                          <img src={info} role="button" alt=""/>

                          <div className="tooltiptext">{fmtData2.section_3.column_2.card_2_icon_2_text.attribute_3_value}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-1">
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
            </div>

            <div className="px-4 align-center">
              <ol className="carrier-section3-list">
                <li className="carrier-section3-list-item">
                  <div style={{ color: '#69707c' }} className="benifits-para">
                    {fmtData2.section_3.column_1.text_1.content}
                  </div>
                </li>

                <li className="carrier-section3-list-item">
                  <div style={{ color: '#69707c' }} className="benifits-para">
                    {fmtData2.section_3.column_1.text_2.content}
                  </div>
                </li>
              </ol>

              <li className="carrier-section3-list-item">
                <div style={{ color: '#69707c' }} className="benifits-para">
                  {fmtData2.section_3.column_1.text_3.content}
                </div>
              </li>

            </div>


          </div>
        </div>

        <div className="container-fluid" style={{ marginTop: '100px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding16">
              <h3
                className="carrier-plan-heading"
                style={{ textAlign: 'center' }}
              >
                {fmtData2.section_4.column_1.title.content}
              </h3>

              <p className="carrier-container-para">
                {fmtData2.section_4.column_1.text.content}
              </p>
            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        <div className="container margin-bottom100 mobRow" style={{ marginTop: '30px' }}>
          <div className="row">
            <div className="col-lg-1"></div>

            <div className="col-lg-10 ">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mx-auto section32 costom-padding16">
                  <div className="card carrier-plan-card mb-4 mb-sm-4 mb-md-4 mb-lg-0 boxNextBy">
                    <div className="card-header carrier-header">
                      <h4
                        className="carrier-plan-heading m-0"
                        style={{ textAlign: 'center', fontWeight: '600' }}
                      >
                        {fmtData2.section_4.column_1.card_title.content}
                      </h4>
                    </div>

                    <div className="card-body">
                      <ul className="progress-container">
                        <li className="progress__item">
                          <p className="progress__title">Quote</p>
                        </li>

                        <li className="progress__item">
                          <div className="progress__title">Application
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{'Complete the initial online application form in just a few minutes!'}</div>
                            </div>
                          </div>
                        </li>

                        <li className="last_child">
                          <div className="progress__title">E-Sign
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{'Once you have completed the application, an electronic copy of the will be emailed for your review and signatures.'}</div>
                            </div>
                          </div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 mx-auto section32 costom-padding16">
                  <div className="card carrier-plan-card mb-4 mb-sm-4 mb-md-4 mb-lg-0 boxNextBy">
                    <div className="card-header carrier-header">
                      <h4
                        className="carrier-plan-heading m-0"
                        style={{ textAlign: 'center', fontWeight: '600' }}
                      >
                        {fmtData2.section_4.column_2.card_title.content}
                      </h4>
                    </div>

                    <div className="card-body">
                      <ul className="progress-container">
                        <li className="progress__item">
                          <div className="progress__title">Telephone Interview
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{'The health check telephone interview should take about 30 minutes of your time and gives us a better understanding of your health and lifestyle.'}</div>
                            </div>
                          </div>
                        </li>

                        <li className="progress__item">
                          <div className="progress__title">Medical Exam (if applicable)
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{`A medical exam may be required for you to be eligible for life insurance with us. This is an in-person health assessment to collect information about your medical history. Your blood pressure, pulse, height, and weight will be recorded.
                              A urine, blood, saliva, or other requirements may be requested.`}</div>
                            </div>
                          </div>
                        </li>

                        <li className="last_child">
                          <div className="progress__title">Underwriting
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{fmtData2.section_4.column_2.card_text.attribute_3_value}</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 mx-auto section32 costom-padding16">
                  <div className="card carrier-plan-card boxNextBy">
                    <div className="card-header carrier-header">
                      <h4
                        className="carrier-plan-heading m-0"
                        style={{ textAlign: 'center', fontWeight: '600' }}
                      >
                        {fmtData2.section_4.column_3.card_title.content}
                      </h4>
                    </div>

                    <div className="card-body">
                      <ul className="progress-container">
                        <li className="progress__item">
                          <div className="progress__title">Offer and Policy Delivery (if applicable)
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

                              <div className="tooltiptext">{'If approved, we’ll notify you of the offer and confirm the issuance of the policy.'}</div>
                            </div>
                          </div>
                        </li>

                        <li className="last_child">
                          <div className="progress__title">E-Sign
                            <div className="tooltipbox">
                              <img src={info} role="button" alt=""/>

s                              <div className="tooltiptext">{fmtData2.section_4.column_3.card_text.attribute_3_value}</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container pacific_life_section1">
          <h3 className="carrier-plan-heading" style={{ textAlign: 'center', marginBottom: '60px' }}>
            {fmtData2.section_5.column_1.title.content}
          </h3>

          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 mb-4 mb-sm-4 mb-md-4 mb-lg-0">
              <img src={fmtData2.section_5.column_1.title.attribute_1_value}
                alt={fmtData2.section_5.column_1.title.attribute_2_value} className="illustration-alignment" style={{ marginBottom: '56px' }}></img>
            </div>

            <div className="col-lg-3"></div>
          </div>

          <div className="row">
            <div className="col-lg-1"></div>

            <div className="col-lg-10">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mx-auto custom-paading-pacific-life">
                  <div className="card carrier-plan-paclife-card mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                    <div className="card-body card-body-padding">
                      <h2
                        className="carrier-plan-heading"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginBottom: '0'
                        }}
                      >
                        {fmtData2.section_5.column_1.card_title_1.content}
                      </h2>

                      <h3
                        className="carrier-plan-heading m-0"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#ff7007'
                        }}
                      >
                        {fmtData2.section_5.column_1.card_title_2.content}
                      </h3>
                    </div>

                    <div
                      className="card-footer carrier-card-footer"
                      style={{ backgroundColor: '#ff7007' }}
                    >
                      <p
                        className="carrier-container-para m-0"
                        style={{ color: 'white' }}
                      >
                        {fmtData2.section_5.column_1.card_text.content}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 mx-auto custom-paading-pacific-life">
                  <div className="card carrier-plan-paclife-card mb-4 mb-sm-4 mb-md-4 mb-lg-0">
                    <div className="card-body card-body-padding">
                      <h2
                        className="carrier-plan-heading"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginBottom: '0'
                        }}
                      >
                        {fmtData2.section_5.column_2.card_title_1.content}
                      </h2>

                      <h3
                        className="carrier-plan-heading m-0"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#ffa61a'
                        }}
                      >
                        {fmtData2.section_5.column_2.card_title_2.content}
                      </h3>
                    </div>

                    <div
                      className="card-footer carrier-card-footer"
                      style={{ backgroundColor: '#ffa61a' }}
                    >
                      <p
                        className="carrier-container-para m-0"
                        style={{ color: 'white' }}
                      >
                        {fmtData2.section_5.column_2.card_text.content}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 mx-auto custom-paading-pacific-life">
                  <div className="card carrier-plan-paclife-card">
                    <div className="card-body card-body-padding">
                      <h2
                        className="carrier-plan-heading"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginBottom: '0'
                        }}
                      >
                        {fmtData2.section_5.column_3.card_title_1.content}
                      </h2>

                      <h3
                        className="carrier-plan-heading m-0"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#00a12f'
                        }}
                      >
                        {fmtData2.section_5.column_3.card_title_2.content}
                      </h3>
                    </div>

                    <div
                      className="card-footer carrier-card-footer"
                      style={{ backgroundColor: '#00a12f' }}
                    >
                      <p
                        className="carrier-container-para m-0"
                        style={{ color: 'white' }}
                      >
                        {fmtData2.section_5.column_3.card_text.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>

        {/* FAQs */}

        <div className="card-bg-containers" id="faqs">
          <div className="faq-box" style={{ paddingBottom: '0px' }}>
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3 className="carrier-plan-heading">Frequently Asked Question</h3>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {data.faq_sections.edges.map( ( s, section_index ) => {
                      if ( visited[s.node.content] == false ) {
                        visited[s.node.content] = true;
                        return (
                          <div className="row" key={section_index}>
                            {/* FAQ Headings */}

                            <div className="col-lg-12">
                              <div className={headerStyles.faqFlex} style={{ backgroundColor: 'white' }}>
                                <div className={headerStyles.faqLeftContainer + ' faq-left-container'}>
                                  <div className="col-sm-12" onClick={function ( e ) { onClick_section( e, section_index ); }} style={{ cursor: 'pointer' }}>
                                    <div className="align-faq-que position-relative">
                                      <div className="faq-section-headings">{s.node.content}</div>

                                      <img src={showSections[section_index] ? UpArrow : DownArrow} className="faq-dd m-0" name={section_index} onClick={function ( e ) { onClick_section( e, section_index ); }} style={{ cursor: 'pointer' }} alt="Bubble Life Insurance" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    {data.faqs.edges.map( ( d, index ) => {
                                      if ( showSections[section_index] && d.node.attribute_1_value == s.node.content ) {
                                        return (
                                          <div className="col-12 accrodianwidth" key={index}>
                                            {/* FAQ Data */ }

                                            <div className={headerStyles.faqSubContainer + ''} >
                                              <div className={headerStyles.faqFlex + ' faq-container-align'}>
                                                <div className={headerStyles.faqLeftContainer + ' row' + ' accrodianwidth'}>
                                                  <div className="col-sm-12 col-12" onClick={function ( e ) { onClick( e, index ); }}>
                                                    <div className="align-faq-que-box-1 align-faq-que-box-padding  position-relative">
                                                      <div className="carrier-faq"> {d.node.attribute_2_key} </div>

                                                      <img src={showResults[index] ? UpArrow : DownArrow} className="faq-dd m-0" name={index} alt="Bubble Life Insurance" />
                                                    </div>

                                                    {showResults[index] ? <div className="pt-lg-0 pt-2 carrier-faq faq-para-text faq-para-text-padding" dangerouslySetInnerHTML={{ __html: d.node.content }}></div> : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    } )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    } )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which policy is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>

      </div>
    </Layout >
  );
};

export default CarrierPlanPage;
